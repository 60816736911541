import { gql } from '@apollo/client';

export const UPDATE_SUBSCRIPTION_SETTINGS_GQL = gql`
    mutation ($model: SubscribeSettingViewModelInput!) {
        subscribeSetting {
            addOrUpdate(model: $model) {
                nameIsRequired
                updateDate
            }
        }
    }
`;

export default UPDATE_SUBSCRIPTION_SETTINGS_GQL;

import React, { FC, Key, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey, SortByCreationDate } from 'enums';
import i18n from 'i18n';
import { PaginationOutput } from 'kl-b2c-ui-kit';
import { useNavigate } from 'react-router-dom';
import { GetSubscriptionsForms, SubscriptionForm } from 'types';
import { getFileFromBytes, getTableColumns } from 'utils';
import { renderCell } from 'utils/cell-renderer';
import { usersCellRenderMapper, usersInitialColumns } from './mappers';
import queryDictionary from 'api/queries';
import mutationDictionary from 'api/mutations';

const SubscribeUsers: FC = () => {
    const [users, setUsers] = useState<PaginationOutput<SubscriptionForm>>({
        count: 0,
        items: null,
    });
    const navigate = useNavigate();

    const [getUsersQuery] = useLazyQuery(queryDictionary.GET_SUBSCRIPTION_FORMS, {
        onCompleted: (response) => {
            const {
                subscriptionForms: { get },
            } = response;
            setUsers(get);
        },
    });

    const [getExcelQuery] = useLazyQuery(queryDictionary.GET_SUBSCRIPTION_FORM_EXCEL, {
        fetchPolicy: 'no-cache',
        onCompleted: (response) => {
            const {
                subscriptionForms: {
                    excelReport: { data, fileName },
                },
            } = response;
            getFileFromBytes(data, fileName);
        },
    });

    const [deleteUsersMutation] = useMutation(mutationDictionary.DELETE_SUBSCRIBED_USER, {
        refetchQueries: [queryDictionary.GET_SUBSCRIPTION_FORMS],
    });

    const getExcel = async (model: Omit<GetSubscriptionsForms, 'page' | 'size'>) => {
        console.log('getExcel');
        await getExcelQuery({
            variables: {
                ...model,
                ascCreationDate:
                    model.ascCreationDate !== SortByCreationDate.All
                        ? model.ascCreationDate === SortByCreationDate.Yes
                        : null,
            },
        });
    };

    const getUsers = async (model: GetSubscriptionsForms) => {
        await getUsersQuery({
            variables: {
                ...model,
                ascCreationDate:
                    model.ascCreationDate !== SortByCreationDate.All
                        ? model.ascCreationDate === SortByCreationDate.Yes
                        : null,
            },
        });
    };

    const deleteItem = async (id: Key): Promise<void> => {
        await deleteUsersMutation({ variables: { id } });
    };

    return (
        <PageBuilder<SubscriptionForm>
            pageKey={PageBuilderKey.SubscribedUsers}
            data={users}
            getItems={(params) => getUsers(params as GetSubscriptionsForms)}
            getExcel={(params) => getExcel(params as Omit<GetSubscriptionsForms, 'page' | 'size'>)}
            deleteItem={(id: Key) => deleteItem(id)}
            columns={getTableColumns<SubscriptionForm>(usersInitialColumns, usersCellRenderMapper, renderCell, {
                ...i18n.getResourceBundle(i18n.language, 'pages/subscribed-users'),
                ...i18n.getResourceBundle(i18n.language, 'common/shared'),
            })}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.AscCreationDate },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Search },
            ]}
            scroll={{ x: 'max-content' }}
            updateItem={(user: SubscriptionForm) => navigate(`/subscribed-user/${user.id}`)}
        />
    );
};

export default SubscribeUsers;

import DELETE_FEEDBACK from './deleteFeedback';
import ADD_FEEDBACK from './addFeedback';
import UPDATE_FEEDBACK from './updateFeedback';

import DELETE_DIRECTION from './deleteDirection';
import ADD_DIRECTION from './addDirection';
import UPDATE_DIRECTION from './updateDirection';

import DELETE_FA_QUESTION from './deleteFAQuestion';
import ADD_FA_QUESTION from './addFAQuestion';
import UPDATE_FA_QUESTION from './updateFAQuestion';

import DELETE_INTERNSHIP_TEAM from './deleteInternshipTeam';
import ADD_INTERNSHIP_TEAM from './addInternshipTeam';
import UPDATE_INTERNSHIP_TEAM from './updateInternshipTeam';

import DELETE_INTERNSHIP_STEP from './deleteInternshipStep';
import ADD_INTERNSHIP_STEP from './addInternshipStep';
import UPDATE_INTERNSHIP_STEP from './updateInternshipStep';

import ADD_DIRECTION_TYPE from './addDirectionType';
import UPDATE_DIRECTION_TYPE from './updateDirectionType';
import DELETE_DIRECTION_TYPE from './deleteDirectionType';

import ADD_COURSE from './addCourse';
import UPDATE_COURSE from './updateCourse';
import DELETE_COURSE from './deleteCourse';

import DELETE_EDU_START_YEAR from './deleteEduStartDate';
import ADD_EDU_START_YEAR from './addEduStartYear';
import UPDATE_EDU_START_YEAR from './updateEduStartYear';

import DELETE_EDU_END_YEAR from './deleteEduEndDate';
import ADD_EDU_END_YEAR from './addEduEndYear';
import UPDATE_EDU_END_YEAR from './updateEduEndYear';

import UPDATE_EMAIL_TPL from './updateEmailTpl';

import UPDATE_INTERNSHIP_REGISTRATION_FORM from './updateInternshipRegistrationForm';

import UPDATE_HOMEPAGE_SETTINGS_FORM from './updateHomepageSettingsForm';

import UPDATE_SUBSCRIPTION_SETTINGS from './updateSubscriptionSettings';

import UPDATE_EVENT_ADT_FORM from './updateEventAdtForm';

import ADD_OR_UPDATE_COURSE_REPORT from './addOrUpdateReports';
import DELETE_INTERNSHIPREGISTRATION from './deleteInternshipRegistration';

import DELETE_SUBSCRIBED_USER from './deleteSubscribedUser';

export const mutationDictionary = {
    DELETE_FEEDBACK,
    ADD_FEEDBACK,
    UPDATE_FEEDBACK,
    DELETE_DIRECTION,
    ADD_DIRECTION,
    UPDATE_DIRECTION,
    DELETE_FA_QUESTION,
    ADD_FA_QUESTION,
    UPDATE_FA_QUESTION,
    DELETE_INTERNSHIP_STEP,
    ADD_INTERNSHIP_STEP,
    UPDATE_INTERNSHIP_STEP,
    DELETE_INTERNSHIP_TEAM,
    ADD_INTERNSHIP_TEAM,
    UPDATE_INTERNSHIP_TEAM,
    ADD_DIRECTION_TYPE,
    UPDATE_DIRECTION_TYPE,
    DELETE_DIRECTION_TYPE,
    ADD_COURSE,
    UPDATE_COURSE,
    DELETE_COURSE,
    UPDATE_INTERNSHIP_REGISTRATION_FORM,
    ADD_OR_UPDATE_COURSE_REPORT,
    DELETE_INTERNSHIPREGISTRATION,
    UPDATE_HOMEPAGE_SETTINGS_FORM,
    UPDATE_EVENT_ADT_FORM,
    DELETE_EDU_START_YEAR,
    ADD_EDU_START_YEAR,
    UPDATE_EDU_START_YEAR,
    DELETE_EDU_END_YEAR,
    ADD_EDU_END_YEAR,
    UPDATE_EDU_END_YEAR,
    UPDATE_EMAIL_TPL,
    UPDATE_SUBSCRIPTION_SETTINGS,
    DELETE_SUBSCRIBED_USER,
};

export default mutationDictionary;

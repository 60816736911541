import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_FORM_EXCEL_GQL = gql`
    query ($ascCreationDate: Boolean, $search: String) {
        subscriptionForms {
            excelReport(filter: { ascCreationDate: $ascCreationDate, search: $search }) {
                data
                fileName
            }
        }
    }
`;

export default GET_SUBSCRIPTION_FORM_EXCEL_GQL;

import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { SubscriptionSettingsModel } from 'types';

export const subscribeSettingsTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<
    keyof SubscriptionSettingsModel,
    { type: ControlRendererType; rules?: RegisterOptions; readonly?: boolean }
> => ({
    nameIsRequired: {
        type: ControlRendererType.CheckBox,
    },
    updateDate: {
        type: ControlRendererType.DateTime,
    },
});

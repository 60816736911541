import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_SETTINGS_GQL = gql`
    query {
        subscribeSetting {
            byId: settings {
                nameIsRequired
                updateDate
            }
        }
    }
`;

export default GET_SUBSCRIPTION_SETTINGS_GQL;

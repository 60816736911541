import GET_TOKEN from './getToken';
import GET_USER_INFO from './getUserInfo';

import GET_FEEDBACKS from './getFeedbacks';
import GET_FEEDBACK from './getFeedback';

import GET_DIRECTIONS from './getDirections';
import GET_DIRECTION from './getDirection';

import GET_FA_QUESTIONS from './getFAQuestions';
import GET_FA_QUESTION_GROUP from './getFAQuestionGroup';
import GET_FA_QUESTION from './getFAQuestion';

import GET_INTERNSHIP_STEP from './getInternshipStep';
import GET_INTERNSHIP_STEPS from './getInternshipSteps';

import GET_INTERNSHIP_TEAM from './getInternshipTeam';
import GET_INTERNSHIP_TEAMS from './getInternshipTeams';

import GET_DIRECTION_TYPE from './getDirectionType';
import GET_DIRECTION_TYPES from './getDirectionTypes';

import GET_AGREEMENTS from './getAgreements';
import GET_AGREEMENT from './getAgreement';

import GET_COURSES from './getCourses';
import GET_COURSE from './getCourse';
import GET_COURSE_WITH_REPORT from './getCourseWithReport';

import GET_INTERNSHIP_REGISTRATION_FORM from './getInternshipRegistrationForm';

import GET_HOMEPAGE_SETTINGS_FORM from './getHomepageSettingsForm';
import GET_SUBSCRIPTION_SETTINGS from './getSubscriptionSettings';

import GET_EVENT_ADT_FORM from './getEventAdtForm';

import GET_INTERNSHIP_REGISTRATIONS from './getInternshipRegistrations';
import GET_INTERNSHIP_REGISTRATION from './getInternshipRegistration';
import DOWNLOAD_INTERNSHIP_REGISTRATIONS from './downloadInternshipRegistrations';

import GET_EDU_START_YEARS from './getEduStartYears';
import GET_EDU_START_YEAR from './getEduStartYear';

import GET_EDU_END_YEARS from './getEduEndYears';
import GET_EDU_END_YEAR from './getEduEndYear';

import GET_REJECTED_EMAIL_TPL from './getRejectedEmailTpl';
import GET_APPLIED_EMAIL_TPL from './getAppliedEmailTpl';

import GET_SUBSCRIPTION_FORM from './getSubscriptionForm';
import GET_SUBSCRIPTION_FORMS from './getSubscriptionForms';
import GET_SUBSCRIPTION_FORM_EXCEL from './getSubscriptionFormsExcel';

export const queryDictionary = {
    GET_TOKEN,
    GET_FEEDBACKS,
    GET_FEEDBACK,
    GET_DIRECTIONS,
    GET_DIRECTION,
    GET_FA_QUESTIONS,
    GET_FA_QUESTION_GROUP,
    GET_FA_QUESTION,
    GET_INTERNSHIP_STEP,
    GET_INTERNSHIP_STEPS,
    GET_INTERNSHIP_TEAM,
    GET_INTERNSHIP_TEAMS,
    GET_DIRECTION_TYPE,
    GET_DIRECTION_TYPES,
    GET_AGREEMENTS,
    GET_AGREEMENT,
    GET_COURSES,
    GET_COURSE,
    GET_INTERNSHIP_REGISTRATION_FORM,
    GET_INTERNSHIP_REGISTRATIONS,
    DOWNLOAD_INTERNSHIP_REGISTRATIONS,
    GET_INTERNSHIP_REGISTRATION,
    GET_COURSE_WITH_REPORT,
    GET_HOMEPAGE_SETTINGS_FORM,
    GET_EVENT_ADT_FORM,
    GET_EDU_START_YEARS,
    GET_EDU_START_YEAR,
    GET_EDU_END_YEARS,
    GET_EDU_END_YEAR,
    GET_REJECTED_EMAIL_TPL,
    GET_APPLIED_EMAIL_TPL,
    GET_USER_INFO,
    GET_SUBSCRIPTION_SETTINGS,
    GET_SUBSCRIPTION_FORM,
    GET_SUBSCRIPTION_FORMS,
    GET_SUBSCRIPTION_FORM_EXCEL,
};

export default queryDictionary;

import { Tabs } from '@kl/components-v6';
import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const elements = [
    {
        tabTitle: 'Registration Form',
        path: '/settings/registration-form',
    },
    {
        tabTitle: 'Subscription Form',
        path: '/settings/subscription-form',
    },
    {
        tabTitle: 'Homepage',
        path: '/settings/homepage',
    },
    {
        tabTitle: 'Event announcement',
        path: '/settings/event-announcement',
    },
    {
        tabTitle: 'Rejected email template',
        path: '/settings/rejected',
    },
    {
        tabTitle: 'Applied email template',
        path: '/settings/applied',
    },
];

const SettingsBase = () => {
    const navigate = useNavigate();

    return (
        <>
            <Tabs activeKey={window.location.pathname} onChange={(path) => navigate(path)}>
                {elements.map((element) => (
                    <Tabs.TabPane tab={element.tabTitle} key={element.path} />
                ))}
            </Tabs>

            <Outlet />
        </>
    );
};

export default SettingsBase;

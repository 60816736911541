import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { GlobalSiteConfig } from 'types';

export const configRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof GlobalSiteConfig, { type: ControlRendererType; rules?: RegisterOptions }> => ({
    updateDate: {
        type: ControlRendererType.DateTime,
    },
    careerStartDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    careerStartTitle: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 100,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 100,
                }),
            },
        },
    },
    directionsDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    directionsTitle: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 100,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 100,
                }),
            },
        },
    },
    introTitle: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 100,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 100,
                }),
            },
        },
    },
    traineeDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    traineeTitle: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 100,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 100,
                }),
            },
        },
    },
    introDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    titleToCareerPortal: {
        type: ControlRendererType.TextBox,
        rules: {
            maxLength: {
                value: 500,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 500,
                }),
            },
        },
    },
});

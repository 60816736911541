import { FaqSubject, PageBuilderKey, SortByCreationDate } from 'enums';
import { PageBuilderFilterType } from 'types';

const baseFilters = {
    page: 0,
    size: 100,
};

export const getFilterMapper = (pageKey: PageBuilderKey): PageBuilderFilterType => {
    if (!filterMapper[pageKey]) {
        throw new Error(`There is no filter mapper for ${pageKey}, consider to add one in to filterMapper object`);
    }

    return filterMapper[pageKey];
};

export const filterMapper: Record<keyof typeof PageBuilderKey, PageBuilderFilterType> = {
    StartDates: {
        ...baseFilters,
    },
    FinishDates: {
        ...baseFilters,
    },
    InternshipTeams: {
        ...baseFilters,
    },
    Types: {
        ...baseFilters,
    },
    Directions: {
        ...baseFilters,
    },
    Steps: {
        ...baseFilters,
    },
    Feedbacks: {
        ...baseFilters,
    },
    FAQs: {
        ...baseFilters,
        subject: FaqSubject.All,
    },
    Courses: {
        ...baseFilters,
    },
    Users: {
        ...baseFilters,
        sortByCreationDate: SortByCreationDate.All,
        stepicCourseId: null,
        stepicId: null,
        email: null,
        lastName: null,
    },
    SubscribedUsers: {
        ...baseFilters,
        sortByCreationDate: SortByCreationDate.All,
        search: null,
    },
    Agreements: {
        ...baseFilters,
        email: null,
    },
};

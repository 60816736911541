import { SubscriptionForm } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';

export const subscribedUserRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof SubscriptionForm, { type: ControlRendererType; rules?: RegisterOptions; readonly?: boolean }> => ({
    id: {
        type: ControlRendererType.Text,
    },
    creationDate: {
        type: ControlRendererType.DateTime,
    },
    email: {
        type: ControlRendererType.Text,
    },
    name: {
        type: ControlRendererType.Text,
    },
    utmTerm: {
        type: ControlRendererType.Text,
    },
    utmMedium: {
        type: ControlRendererType.Text,
    },
    utmCampaign: {
        type: ControlRendererType.Text,
    },
    utmSource: {
        type: ControlRendererType.Text,
    },
    utmContent: {
        type: ControlRendererType.Text,
    },
    agreementId: {
        type: ControlRendererType.Text,
    },
});

import { gql } from '@apollo/client';

export const DELETE_SUBSCRIBED_USER_GQL = gql`
    mutation ($id: UUID!) {
        subscribeForms {
            delete(model: { id: $id })
        }
    }
`;

export default DELETE_SUBSCRIBED_USER_GQL;

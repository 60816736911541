import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_FORMS_GQL = gql`
    query ($page: Int!, $size: Int!, $ascCreationDate: Boolean, $search: String) {
        subscriptionForms {
            get(filter: { page: $page, size: $size, ascCreationDate: $ascCreationDate, search: $search }) {
                count
                items {
                    id
                    agreementId
                    creationDate
                    name
                    email
                    utmTerm
                    utmMedium
                    utmCampaign
                    utmSource
                    utmContent
                }
            }
        }
    }
`;

export default GET_SUBSCRIPTION_FORMS_GQL;

export enum PageBuilderKey {
    StartDates = 'StartDates',
    FinishDates = 'FinishDates',
    InternshipTeams = 'InternshipTeams',
    Types = 'Types',
    Directions = 'Directions',
    Steps = 'Steps',
    Feedbacks = 'Feedbacks',
    FAQs = 'FAQs',
    Courses = 'Courses',
    Users = 'Users',
    SubscribedUsers = 'SubscribedUsers',
    Agreements = 'Agreements',
}

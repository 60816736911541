export enum FilterType {
    SortByName = 'sortByName',
    Subject = 'subject',
    SortByCreationDate = 'sortByCreationDate',
    AscCreationDate = 'ascCreationDate',
    StepicCourseId = 'stepicCourseId',
    Email = 'email',
    Search = 'search',
    StepicId = 'stepicId',
    LastName = 'lastName',
}

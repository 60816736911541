import { gql } from '@apollo/client';

export const GET_COURSE_GQL = gql`
    query ($id: UUID!) {
        courses {
            byId(filter: { id: $id }) {
                id
                creationDate
                stepikCourseId
                redirectLink
            }
        }
    }
`;

export default GET_COURSE_GQL;

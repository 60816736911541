export enum FormBuilderKeys {
    Config = 'Config',
    SubscribeSetting = 'SubscribeSetting',
    EventAnnouncement = 'EventAnnouncement',
    RejectedEmail = 'RejectedEmail',
    AppliedEmail = 'AppliedEmail',
    RegistrationForm = 'RegistrationForm',
    AddDates = 'AddDates',
    InternshipTeam = 'InternshipTeam',
    Type = 'Type',
    Direction = 'Direction',
    Step = 'Step',
    Feedback = 'Feedback',
    FAQ = 'FAQ',
    Course = 'Course',
    User = 'User',
    SubscribedUser = 'SubscribedUser',
    Agreement = 'Agreement',
}

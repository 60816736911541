import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Loader, Select } from '@kl/components-v6';
import UPDATE_INTERNSHIP_REGISTRATION_GQL from 'api/mutations/updateUser';
import GET_DIRECTIONS_GQL from 'api/queries/getDirections';
import { queryDictionary } from 'api/queries';
import { FormBuilder } from 'containers';
import { ErrorMessage } from 'containers/form-builder/styled';
import { useToaster } from 'contexts/index';
import { FormBuilderKeys } from 'enums';
import React, { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormRow } from 'styled/global-style';
import { SubscriptionForm } from 'types';

export const INITIAL_DATA: SubscriptionForm = {
    id: '',
    creationDate: new Date(),
    email: '',
    name: '',
    utmCampaign: '',
    utmContent: '',
    utmMedium: '',
    utmSource: '',
    utmTerm: '',
    agreementId: '',
};

const SubscribeUser: FC = () => {
    const [user, setUser] = useState<SubscriptionForm>(INITIAL_DATA);
    const { t } = useTranslation(['pages/subscribed-users', 'common/shared']);
    const navigate = useNavigate();
    const { setToaster } = useToaster();

    const [getUserQuery, { loading: userLoading }] = useLazyQuery(queryDictionary.GET_SUBSCRIPTION_FORM, {
        onCompleted: (response) => {
            setUser(response.subscriptionForms.byId);
        },
    });

    const { id } = useParams();
    const methods = useForm<SubscriptionForm>();
    const {
        control,
        formState: { errors },
        watch,
    } = methods;

    useEffect(() => {
        if (id) {
            getUserQuery({ variables: { id } });
        }
    }, []);

    return !userLoading ? (
        <FormProvider {...methods}>
            <FormBuilder<SubscriptionForm>
                data={user}
                formKey={FormBuilderKeys.SubscribedUser}
                loading={userLoading}
                cancel={() => navigate('/subscribed-users')}
                fullWidth
            />
        </FormProvider>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default SubscribeUser;

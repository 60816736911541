import React, { FC, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { SubscriptionSettingsModel } from 'types/global-site-config';
import UPDATE_SUBSCRIPTION_SETTINGS_GQL from 'api/mutations/updateSubscriptionSettings';
import { queryDictionary } from 'api/queries';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'contexts/toaster.context';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Heading, Icon, Loader } from '@kl/components-v6';
import FormBuilder from 'containers/form-builder';
import { FormBuilderKeys } from 'enums/form-builder-keys';
import { FormRow } from 'styled/global-style';

const SubscriptionSettings: FC = () => {
    const [settings, setSettings] = useState<SubscriptionSettingsModel>();
    const [configMutation, { loading: mutationLoading }] = useMutation(UPDATE_SUBSCRIPTION_SETTINGS_GQL);
    const { loading } = useQuery(queryDictionary.GET_SUBSCRIPTION_SETTINGS, {
        onCompleted: (response) => {
            setSettings(response.subscribeSetting.byId);
        },
    });

    const { t } = useTranslation(['pages/config', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<Omit<SubscriptionSettingsModel, 'updateDate'>>();

    const onSubmit = async (data: FieldValues) => {
        try {
            const response = await configMutation({
                variables: { model: { ...data, updateDate: new Date().toISOString() } },
            });
            const {
                data: {
                    subscribeSetting: { addOrUpdate },
                },
            } = response;
            setSettings(addOrUpdate);
            setToaster({
                type: 'success',
                message: t('config-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    return !settings || loading ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('subscriptionSettings')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<SubscriptionSettingsModel>
                    data={settings}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.SubscribeSetting}
                    loading={mutationLoading}
                >
                    <FormRow>
                        <span>{t('emailIsRequired', { ns: 'common/shared' })}</span>
                        <Icon
                            size={'small'}
                            // @ts-ignore
                            name={'Check'}
                            color={'positive'}
                        />
                    </FormRow>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default SubscriptionSettings;

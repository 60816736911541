import { CellRenderType } from 'kl-b2c-ui-kit';
import { SubscriptionForm } from 'types';

export const usersInitialColumns: SubscriptionForm = {
    id: '',
    name: '',
    email: '',
    agreementId: '',
    creationDate: new Date(),
    utmCampaign: '',
    utmContent: '',
    utmMedium: '',
    utmSource: '',
    utmTerm: '',
};

export const usersCellRenderMapper: Record<keyof SubscriptionForm, CellRenderType> = {
    id: CellRenderType.TEXT,
    name: CellRenderType.TEXT,
    email: CellRenderType.TEXT,
    agreementId: CellRenderType.AGREEMENT_ID,
    creationDate: CellRenderType.DATETIME,
    utmCampaign: CellRenderType.TEXT,
    utmContent: CellRenderType.TEXT,
    utmMedium: CellRenderType.TEXT,
    utmSource: CellRenderType.TEXT,
    utmTerm: CellRenderType.TEXT,
};

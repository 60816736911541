import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_FORM_GQL = gql`
    query ($id: UUID!) {
        subscriptionForms {
            byId(filter: { id: $id }) {
                id
                creationDate
                agreementId
                name
                email
                utmTerm
                utmMedium
                utmCampaign
                utmSource
                utmContent
            }
        }
    }
`;

export default GET_SUBSCRIPTION_FORM_GQL;

import { gql } from '@apollo/client';

export const UPDATE_COURSE_GQL = gql`
    mutation ($model: UpdateCourseViewModelInput!) {
        courses {
            update(model: $model) {
                id
                creationDate
                stepikCourseId
                redirectLink
                name
            }
        }
    }
`;

export default UPDATE_COURSE_GQL;

import { gql } from '@apollo/client';

export const GET_FA_QUESTIONS_GROUP_GQL = gql`
    query ($subject: QuestionType!) {
        questions {
            byId(filter: { subject: $subject }) {
                creationDate
                id
                question
                answer
                subject
                number
                visible
            }
        }
    }
`;

export default GET_FA_QUESTIONS_GROUP_GQL;
